import { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import Link from "next/link";

import Button from "components/ui/button/Button";
import IconCheckmark from "components/ui/icons/IconCheckmark";
import IconCookies from "components/ui/icons/IconCookies";
import IconCross from "components/ui/icons/IconCross";

import {
    CookieConsentStyled,
    Close,
    Heading,
    Text,
    StyledLink,
    Option,
    OptionButton,
    OptionText,
    Controls
} from "./CookieConsent.styled";

function pushPreferencesToDataLayer(statistics, marketing) {
    window?.dataLayer?.push({ event: "cookies_preferences" });

    if (statistics) {
        window?.dataLayer?.push({ event: "cookies_statistics" });
    }

    if (marketing) {
        window?.dataLayer?.push({ event: "cookies_marketing" });
    }
}

export default function CookieConsent() {
    const [preferencesCookies, setPreferencesCookies] = useState(true);
    const [statisticsCookies, setStatisticsCookies] = useState(true);
    const [marketingCookies, setMarketingCookies] = useState(true);
    const [savedPreferences, setSavedPreferences] = useState(true);

    function saveCookiePreferences() {
        const baseUrl = new URL("/", process.env.NEXT_PUBLIC_BASE_URL);
        const domain = baseUrl.hostname;
        const options = {
            expires: 7,
            domain
        };

        Cookies.set("cookiesPreferences", preferencesCookies, options);
        Cookies.set("cookiesStatistics", statisticsCookies, options);
        Cookies.set("cookiesMarketing", marketingCookies, options);

        pushPreferencesToDataLayer(statisticsCookies, marketingCookies);
        setSavedPreferences(true);
    }

    const allCookiesSet = useMemo(() => {
        // Checks if the cookies have been already set locally.
        const preferencesCookieExists = Cookies.get("cookiesPreferences");
        const statisticsCookieExists = Cookies.get("cookiesStatistics");
        const marketingCookieExists = Cookies.get("cookiesMarketing");

        return !(
            preferencesCookieExists === undefined ||
            statisticsCookieExists === undefined ||
            marketingCookieExists === undefined
        );
    }, []);

    useEffect(() => {
        setSavedPreferences(allCookiesSet);
    }, [allCookiesSet]);

    if (allCookiesSet) {
        return null;
    }

    return (
        <CookieConsentStyled active={!savedPreferences}>
            <Close
                onClick={saveCookiePreferences}
                aria-label="opslaan"
                role="button"
            >
                <IconCross size={18} fill="var(--color-white)" />
            </Close>
            <Heading>
                <IconCookies
                    size={44}
                    fill="var(--color-primary)"
                    aria-hidden="true"
                />
                Wij maken gebruik van cookies
            </Heading>
            <Text>
                Voor de optimale beleving van Nationale Diner-Cadeau maken wij
                gebruik van cookies. Door gebruik te maken van onze website ga
                je akkoord met ons{" "}
                <Link href="/privacy/" passHref prefetch={false}>
                    <StyledLink>Cookies & Privacy statement</StyledLink>
                </Link>
                .
            </Text>
            <Option>
                <OptionButton
                    type="button"
                    isActive={preferencesCookies}
                    onClick={() => setPreferencesCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheckmark size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!preferencesCookies}
                    isNegative
                    onClick={() => setPreferencesCookies(false)}
                    aria-label="weigeren"
                >
                    <IconCross size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionText>Voorkeuren</OptionText>
            </Option>
            <Option>
                <OptionButton
                    type="button"
                    isActive={statisticsCookies}
                    onClick={() => setStatisticsCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheckmark size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!statisticsCookies}
                    isNegative
                    onClick={() => setStatisticsCookies(false)}
                    aria-label="weigeren"
                >
                    <IconCross size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionText>Statistieken</OptionText>
            </Option>
            <Option>
                <OptionButton
                    type="button"
                    isActive={marketingCookies}
                    onClick={() => setMarketingCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheckmark size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!marketingCookies}
                    isNegative
                    onClick={() => setMarketingCookies(false)}
                    aria-label="weigeren"
                >
                    <IconCross size={20} fill="var(--color-gray-60)" />
                </OptionButton>
                <OptionText>Marketing</OptionText>
            </Option>
            <Controls>
                <Button
                    variant="primary"
                    onClick={saveCookiePreferences}
                    aria-label="opslaan"
                >
                    Akkoord
                </Button>
                <Link href="/privacy/" passHref prefetch={false}>
                    <Button element="a" variant="ghost">
                        Meer details
                    </Button>
                </Link>
            </Controls>
        </CookieConsentStyled>
    );
}
