import { css } from "styled-components";

export const sizes = {
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200
};

const customMediaQuery = (width, bound = "min") =>
    `@media (${bound}-width: ${width}px)`;

const queries = {
    lgUp: customMediaQuery(sizes.lg),
    mdDown: customMediaQuery(sizes.md - 1, "max"),
    mdUp: customMediaQuery(sizes.md),
    smUp: customMediaQuery(sizes.sm),
    xlDown: customMediaQuery(sizes.xl - 1, "max"),
    xlUp: customMediaQuery(sizes.xl)
};

const mobileHide = css`
    @media (max-width: ${sizes.md - 1}px) {
        display: none;
    }
`;

const mobileOnly = css`
    @media (min-width: ${sizes.md}px) {
        display: none;
    }
`;

const tabletHide = css`
    @media (min-width: ${sizes.md}px) and (max-width: ${sizes.lg - 1}px) {
        display: none;
    }
`;

const tabletOnly = css`
    @media (max-width: ${sizes.md - 1}px), (min-width: ${sizes.lg}px) {
        display: none;
    }
`;

const desktopHide = css`
    @media (min-width: ${sizes.lg}px) {
        display: none;
    }
`;

const desktopOnly = css`
    @media (max-width: ${sizes.lg - 1}px) {
        display: none;
    }
`;

const media = {
    ...queries,
    mobileHide,
    mobileOnly,
    tabletHide,
    tabletOnly,
    desktopHide,
    desktopOnly
};

export default media;
