import PropTypes from "prop-types";

function IconCross({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M50 18.24 45.76 14 32.68 27.08 19.59 14 15.35 18.24 28.43 31.32 15.35 44.41 19.59 48.65 32.68 35.57 45.76 48.65 50 44.41 36.92 31.32 50 18.24z" />
        </svg>
    );
}

IconCross.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCross.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconCross;
