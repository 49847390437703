import PropTypes from "prop-types";

function IconCookies({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M29.89,18.84A2.5,2.5,0,1,0,26.46,18,2.5,2.5,0,0,0,29.89,18.84Zm12,28A2.5,2.5,0,1,0,38.46,46,2.5,2.5,0,0,0,41.89,46.84Zm-11-6A2.5,2.5,0,1,0,27.46,40,2.51,2.51,0,0,0,30.9,40.84ZM20.46,34a2.5,2.5,0,1,0,.83-3.44A2.5,2.5,0,0,0,20.46,34Zm1.43,10.83A2.5,2.5,0,1,0,18.46,44,2.5,2.5,0,0,0,21.89,44.84ZM19.05,20.5a2,2,0,1,0,2.75.66A2,2,0,0,0,19.05,20.5ZM48,35.08a6.23,6.23,0,0,0,6.12,3,23.81,23.81,0,0,1-43.51,6.65,24.09,24.09,0,0,1,8-33A23.7,23.7,0,0,1,40,10a6.74,6.74,0,0,0-2.19,9.21,6.83,6.83,0,0,0,1.7,1.88,9.91,9.91,0,0,0,1.37,6.17,9.8,9.8,0,0,0,6.17,4.48A6.27,6.27,0,0,0,48,35.08Z" />
        </svg>
    );
}

IconCookies.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCookies.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconCookies;
