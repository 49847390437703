const colors = {
    white: "#ffffff",
    black: "#000000",
    gray20: "#212123",
    gray30: "#656579",
    gray40: "#7e7e7e",
    gray50: "#808080",
    gray60: "#9595a6",
    gray65: "#c2d1d9",
    gray70: "#d6e1e6",
    gray80: "#f0f6f8",
    gray85: "#f9f9f9",
    gray90: "#fcfcfd",
    gray95: "#fdfdfe",
    purple20: "#371844",
    purple30: "#460961",
    purple40: "#662483",
    purple50: "#7f4998",
    purple75: "#ebe0f2",
    purple85: "#f6f4f9",
    purple90: "#f7f4f9",
    purple95: "#fcfbfd",
    green40: "#50972c",
    green50: "#72bf44",
    green60: "#f0f8ec",
    yellow: "#f9b104",
    red40: "#ca4632",
    red50: "#ff3e05",
    red60: "#fee9e6"
};

colors.purple = colors.purple40;
colors.red = colors.red40;
colors.green = colors.green50;

colors.primary = colors.purple40;
colors.secondary = colors.yellow;
colors.tertiary = colors.gray20;

export default colors;
