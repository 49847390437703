import PropTypes from "prop-types";

function IconCheckmark({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={fill}
            {...rest}
        >
            <path d="M29.34 45.35 56.2 18.48 51.96 14.24 25.09 41.11 10.24 26.26 6 30.5 25.09 49.59 29.34 45.35z" />
        </svg>
    );
}

IconCheckmark.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCheckmark.defaultProps = {
    fill: "var(--color-black)",
    size: 26,
    style: { verticalAlign: "middle" }
};

export default IconCheckmark;
