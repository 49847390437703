/* eslint-disable react/no-danger */
import NextHead from "next/head";
import { useRouter } from "next/router";

function Base() {
    const router = useRouter();
    const pathname = router.asPath;

    return (
        <NextHead>
            {/* @TODO Remove noindex when removing A/B test with www2 */}
            <meta name="robots" content="noindex,nofollow" />
            <link
                rel="preconnect"
                href={process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}
                crossOrigin="anonymous"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin="anonymous"
            />
            <link
                rel="dns-prefetch preconnect"
                as="script"
                href="https://www.googletagmanager.com/"
                crossOrigin="anonymous"
            />
            <link
                rel="dns-prefetch preconnect"
                href="https://maps.googleapis.com/"
                crossOrigin="anonymous"
            />
            <link rel="icon" href="/favicon.ico" />
            <link rel="icon" sizes="16x16 32x32 64x64" href="/favicon.ico" />
            <link
                rel="icon"
                type="image/png"
                sizes="196x196"
                href="/favicon-192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="160x160"
                href="/favicon-160.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicon-96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="64x64"
                href="/favicon-64.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16.png"
            />
            <link rel="apple-touch-icon" href="/favicon-57.png" />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/favicon-114.png"
            />
            <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72.png" />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/favicon-144.png"
            />
            <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60.png" />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/favicon-120.png"
            />
            <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76.png" />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/favicon-152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicon-180.png"
            />
            <link
                rel="manifest"
                href="/manifest.json"
                crossOrigin="use-credentials"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="mobile-web-app-capable" content="yes" />

            <meta name="theme-color" content="#662483" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="/favicon-144.png" />
            <meta name="msapplication-tap-highlight" content="no" />
            <meta name="msapplication-config" content="/browserconfig.xml" />
            <meta
                property="og:image"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}/images/seo-banner-1200x600.jpg`}
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="600" />
            <meta property="og:type" content="website" />
            <meta
                property="og:url"
                content={process.env.NEXT_PUBLIC_BASE_URL}
            />
            <meta name="twitter:card" content="summary" />
            <meta
                name="twitter:url"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}${pathname}`}
            />
            <meta
                property="twitter:image"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}/images/seo-banner-1200x630.jpg`}
            />
        </NextHead>
    );
}

export default Base;
