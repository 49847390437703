import styled, { css } from "styled-components";

import media from "constants/media";

export const CookieConsentStyled = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 30;

    width: calc(100% - 40px);
    max-width: 300px;
    padding: 20px;

    background: var(--color-white);
    border-radius: var(--border-radius-default);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);

    ${({ active }) =>
        active
            ? css`
                  content-visibility: visible;
                  display: block;
              `
            : css`
                  content-visibility: hidden;
                  display: none;
                  pointer-events: none;
              `}

    ${media.mdUp} {
        padding: 25px 15px 30px 30px;
    }
`;

export const Close = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;

    width: 20px;
    height: 20px;
    padding: 0;

    background-color: var(--color-red-40);
    border: none;
    border-radius: 100%;
    cursor: pointer;

    :hover,
    :focus {
        background-color: var(--color-primary);
    }
`;

export const Heading = styled.h2`
    max-width: 240px;
    margin: 0 20px 10px 0;

    font-weight: bold;
    font-size: var(--font-size-body);
    line-height: 25px;

    ${media.mdUp} {
        line-height: var(--line-height-body);
    }

    svg {
        float: left;
        margin-right: 10px;
    }
`;

export const Text = styled.p`
    margin: 0 0 25px;

    font-size: 14px;
    line-height: 25px;

    ${media.mdUp} {
        margin: 0 20px 25px 0;

        font-size: var(--font-size-body);
        line-height: var(--line-height-body);
    }
`;

export const StyledLink = styled.a`
    color: var(--color-primary);
    text-decoration: underline;
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    :last-of-type {
        margin-bottom: 26px;
    }
`;

export const OptionButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;

    background: transparent;
    border: 1px solid var(--color-gray-80);

    border-radius: 50%;
    cursor: pointer;

    transition: 0.2s all ease-in-out;

    ${({ isActive }) =>
        isActive &&
        css`
            background: var(
                --color-${({ isNegative }) => (isNegative ? "red" : "green")}
            );
            border: 1px solid transparent;

            svg {
                fill: var(--color-white);
            }
        `}

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    svg {
        transition: 0.2s fill ease-in-out;
    }
`;

export const OptionText = styled.span`
    margin-left: 20px;

    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    ${media.mdUp} {
        font-size: var(--font-size-body);
        line-height: var(--line-height-body);
    }
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;

    button:first-of-type {
        margin-right: 30px;
    }
`;
