import styled, { css } from "styled-components";

export const ButtonStyled = styled.button`
    position: relative;

    display: inline-flex;
    gap: 0 10px;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 15px 20px;
    overflow: hidden;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);

    border-radius: var(--border-radius-default);

    cursor: pointer;

    transition: all var(--animation-duration-default) ease;

    :disabled {
        cursor: not-allowed;
        opacity: 0.5;

        user-select: none;

        pointer-events: none;
    }

    ${({ variant }) =>
        variant === "primary" &&
        css`
            color: var(--color-white);

            background-color: var(--color-purple);
            border: 0;

            box-shadow: 0 0.4px 1.31px 0 #9a1cf314, 0 1.12px 3.63px 0 #9a1cf31c,
                0 2.71px 8.74px 0 #9a1cf325, 0 9px 29px 0 #9a1cf338;

            @media (any-hover: hover) {
                :active,
                :focus,
                :hover {
                    background-color: var(--color-purple-30);
                    box-shadow: 0 0.41px 1.31px 0 #9a1cf328,
                        0 1.13px 3.63px 0 #9a1cf339, 0 2.71px 8.74px 0 #9a1cf34b,
                        0 9px 29px 0 #9a1cf373;
                }
            }
        `}

    ${({ variant }) =>
        variant === "secondary" &&
        css`
            color: var(--color-purple);

            background-color: var(--color-white);
            border: none;

            svg {
                fill: var(--color-purple);
            }

            @media (any-hover: hover) {
                :active,
                :focus,
                :hover {
                    color: var(--color-white);

                    background-color: var(--color-yellow);

                    svg {
                        fill: var(--color-white);
                    }
                }
            }
        `}

    ${({ variant }) =>
        variant === "tertiary" &&
        css`
            color: var(--color-white);
            font-size: var(--font-size-body);

            background-color: var(--color-green);
            border: 0;
            box-shadow: 0 14px 20px rgba(114, 191, 68, 0.15);

            @media (any-hover: hover) {
                :active,
                :hover {
                    background-color: var(--color-green-40);
                }
            }
        `}

    ${({ variant }) =>
        variant === "outline" &&
        css`
            color: var(--color-purple);

            background-color: var(--color-white);
            border: 1px solid var(--color-purple);

            svg {
                fill: var(--color-purple);
            }

            @media (any-hover: hover) {
                :active,
                :focus,
                :hover {
                    color: var(--color-white);

                    background-color: var(--color-purple);
                    border: 1px solid var(--color-purple);

                    svg {
                        fill: var(--color-white);
                    }
                }
            }
        `}

    ${({ variant }) =>
        variant === "ghost" &&
        css`
            padding: 0;

            color: var(--color-purple);
            height: 40px;
            margin: 5px 0;

            background-color: transparent;
            border: 1px solid transparent;
            border-radius: 0;

            @media (any-hover: hover) {
                :active,
                :focus,
                :hover {
                    border-bottom-color: var(--color-primary);
                }
            }
        `}

    ${({ variant }) =>
        variant === "link" &&
        css`
            padding: 0;

            color: var(--color-yellow);
            height: 40px;
            margin: 5px 0;

            background-color: transparent;
            border: 1px solid transparent;
            border-radius: 0;

            @media (any-hover: hover) {
                :active,
                :focus,
                :hover {
                    border-bottom-color: var(--color-yellow);
                }
            }
        `}
`;
