import { useMemo } from "react";
import {
    ApolloClient,
    ApolloLink,
    from,
    InMemoryCache
} from "@apollo/client/core";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import ApolloLinkTimeout from "apollo-link-timeout";
import merge from "deepmerge";
import isEqual from "lodash.isequal";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

function createApolloClient() {
    // Aborts requests that take longer than 10000ms.
    const timeoutLink = new ApolloLinkTimeout(10000);

    const httpLink = new RetryLink().split(
        operation => operation.getContext().name === "polly",
        new HttpLink({
            uri: process.env.NEXT_PUBLIC_POLLY_GRAPHQL_API,
            includeUnusedVariables: true
        }),
        new HttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL,
            headers: {
                "X-Store": process.env.NEXT_PUBLIC_STORE_ID,
                "Access-Control-Allow-Origin": "*",
                Origin: process.env.NEXT_PUBLIC_BASE_URL
            },
            credentials: "include"
        })
    );

    const sessionCookieMiddleware = new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
            const context = operation.getContext();
            const {
                response: { headers }
            } = context;

            if (headers) {
                const setCookie = headers.get("set-cookie");

                if (setCookie && typeof document !== "undefined") {
                    const [sessionCookie] = setCookie.split(",");
                    document.cookie = sessionCookie;
                }
            }

            return response;
        });
    });

    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        link: from([timeoutLink, sessionCookieMiddleware, httpLink]),
        shouldBatch: true,
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        merchants: {
                            edges: {
                                keyArgs: ["filter", "currentPage", "pageSize"],
                                // eslint-disable-next-line default-param-last
                                merge(existing = [], incoming) {
                                    return [...existing, ...incoming];
                                }
                            }
                        }
                    }
                },
                ProductVariant: {
                    keyFields: ["sku"]
                },
                Address: {
                    keyFields: [
                        "postalCode",
                        "houseNumber",
                        "suffix",
                        "country"
                    ]
                },
                Cart: {
                    fields: {
                        items: {
                            merge: false
                        },
                        totals: {
                            merge(existing, incoming) {
                                return incoming;
                            }
                        }
                    }
                }
            }
        })
    });
}

export function initializeApollo(initialState = null) {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter(d =>
                    sourceArray.every(s => !isEqual(d, s))
                )
            ]
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") {
        return _apolloClient;
    }

    // Create the Apollo Client once in the client
    if (!apolloClient) {
        apolloClient = _apolloClient;
    }

    return _apolloClient;
}

export function addApolloState(client, pageProps) {
    const properties = pageProps;
    if (properties?.props) {
        properties.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initializeApollo(state), [state]);
}
