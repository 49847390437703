import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import * as gtm from "lib/gtag";
import Script from "next/script";

const handleRouteChange = url => {
    gtm.pageview(url);
};

function GoogleTagManager({ children }) {
    const router = useRouter();
    const [datalayerSet, setDatalayerSet] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        window.dataLayer = window?.dataLayer || [];
        window?.dataLayer?.push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        setDatalayerSet(true);
    }, []);

    return (
        <>
            {datalayerSet && (
                <Script
                    src={`https://www.googletagmanager.com/gtm.js?id=${gtm.GTM_ID}`}
                    strategy="afterInteractive"
                />
            )}
            {children}
        </>
    );
}

GoogleTagManager.propTypes = {
    children: PropTypes.node.isRequired
};

export default GoogleTagManager;
