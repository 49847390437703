export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
    window?.dataLayer?.push({
        event: "pageview",
        page: url
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window?.dataLayer?.push({
        event: action,
        event_category: category,
        event_label: label,
        value
    });
};

export const purchase = (info, items) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            purchase: {
                actionField: {
                    ...info
                },
                products: items
            }
        }
    });
};
